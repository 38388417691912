import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  // BehaviorSubject guarda un valor inicial (en este caso 'Dashboard')
  private _titleSubject = new BehaviorSubject<string>('Dashboard');
  
  // Exponemos el observable para que otros se suscriban
  title$ = this._titleSubject.asObservable();

  constructor() {}

  // Método para cambiar el título
  setTitle(newTitle: string) {
    this._titleSubject.next(newTitle);
  }
}
